import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchFeatures(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/feature/leerlista', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchActiveFeatures(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/feature/leerlistaactivos', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchFeature(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/feature/leerfeature?id=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFeature(ctx, featureData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/feature/recibefeature', { json: featureData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMarkets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/market/leerlista', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
