<template>
  <v-card
    v-if="data.featureData"
    class="app-invoice-editable"
  >
    <v-toolbar
      class="primary"
      short
    >
      <v-toolbar-title style="color:white;">
        Add/Edit Feature
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        color="primary"
        small
        :disabled="!data.featureData.valid"
        @click="onSubmit"
      >
        Save Record
      </v-btn>
      <v-btn
        color="secondary"
        small
        outlined
        @click="regresarListado"
      >
        Return to List
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
    <v-divider></v-divider>
    <v-card-text>
      <v-form
        ref="form"
        v-model="data.featureData.valid"
        @submit.prevent="onSubmit"
      >
        <v-row dense>
          <v-col
            cols="12"
            md="3"
          >
            <label>Market:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <!-- <v-text-field
              v-model="data.featureData.market"
              v-uppercase
              outlined
              dense
              required
              :rules="validators.formatRegular"
              placeholder="Short Name of the Market"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field> -->
            <v-select
              v-model="data.featureData.market"
              outlined
              :menu-props="{ offsetY: true }"
              :items="marketsLista"
              dense
              item-text="marketShortName"
              item-value="marketShortName"
              placeholder="Select Market"
              :rules="validators.required"
              autocomplete="off"
              class="select-invoice-to"
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Feature Name:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.featureData.featureName"
              v-uppercase
              outlined
              dense
              required
              :rules="validators.formatRegular"
              placeholder="Description of the Feature"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Feature Value:</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="data.featureData.featureValue"
              v-uppercase
              outlined
              dense
              placeholder="Value"
              label="Value"
              autocomplete="off"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <label>Is Active?</label>
          </v-col>

          <v-col
            cols="12"
            md="8"
          >
            <v-checkbox
              v-model="data.featureData.isActive"
              outlined
              dense
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import themeConfig from '@themeConfig'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import { mdiDotsVertical, mdiClose, mdiCogOutline } from '@mdi/js'
import ctFeatureStore from './ctFeatureStore'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    // ————————————————————————————————————
    //* ——— Store Registration
    // ————————————————————————————————————

    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const featureDataLocal = ref({})
    featureDataLocal.value = JSON.parse(JSON.stringify(props.data))

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) {
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, ctFeatureStore)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const marketsLista = ref([])
    store.dispatch('app-invoice/fetchMarkets').then(response => {
      marketsLista.value = response.data
    })

    const regresarListado = () => {
      router.go(-1)
    }

    const onSubmit = () => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      featureDataLocal.value = JSON.parse(JSON.stringify(props.data))

      featureDataLocal.value.featureData.usuarioRegistro = userData.email
      featureDataLocal.value.featureData.usuarioModifico = userData.email

      store.dispatch('app-invoice/addFeature', featureDataLocal.value.featureData).then(() => {
        Swal.fire({
          text: 'Feature Added Successfully!',
          toast: true,
          position: 'top-right',
          icon: 'success',
          customClass: {
            popup: 'colored-toast',
          },
          timer: 1500,
          timerProgressBar: true,
          showConfirmButton: false,
        })
        router.go(-1)
      })
    }

    return {
      onSubmit,
      regresarListado,
      featureDataLocal,
      marketsLista,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      validators: {
        // Condiciones
        formatRegular: [
          v => !!v || 'This field is mandatory',
        ],
        formatFloat: [
          v => !Number.isNaN(Number(v)) || 'The value of the field must be a floating number',
        ],
      },

      // Icons
      icons: {
        mdiClose,
        mdiCogOutline,
        mdiDotsVertical,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
</style>
